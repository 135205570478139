import { Controller } from "stimulus"

export default class extends Controller {
  static targets =[
    'form', 'result', 'reset', 'filtering', 'productInput', 'countryInput', 'statusInput'
  ]
  static values = { url: String }

  connect() {
    const productInputSelected =
      this.productInputTarget.value != ''
    const countryInputSelected =
      this.countryInputTarget.options[this.countryInputTarget.selectedIndex].value != ''
    const statusInputSelected =
      this.statusInputTarget.value != ''

    if (productInputSelected || countryInputSelected || statusInputSelected) {
      this.displayResetButton()
    }
  }

  displayResetButton() {
    this.resetTarget.classList.remove('hidden')
  }

  submit() {
    window.Rails.fire(this.formTarget, 'submit')
  }

  result(event) {
    const data = event.detail[0]
    if (data.length > 0) {
      this.resultTarget.innerHTML = data
      this.resetTarget.classList.toggle('hidden', this.filteringTarget.dataset.filtering != 'true')
    }
  }
}
