import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["button", "secret"]

  replaceBySecret() {
    fetch(this.element.dataset.url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.buttonTarget.classList.add("hidden");
          this.secretTarget.classList.remove("hidden");
        }
      });
  }
}