import { Controller } from "stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["usersBarChart", "productsPieChart", "redeemsBarChart", "savingsBarChart"];

  connect() {
    if (this.hasUsersBarChartTarget) { this.usersBarChart() };
    if (this.hasProductsPieChartTarget) { this.productsPieChart() };
    if (this.hasRedeemsBarChartTarget) { this.redeemsBarChart() };
    if (this.hasSavingsBarChartTarget) { this.savingsBarChart() };
  }

  usersBarChart() {
    new Chart(this.usersBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.usersBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "New users",
          data: this.usersBarChartTarget.dataset.users.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  savingsBarChart() {
    new Chart(this.savingsBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.savingsBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "Savings ($)",
          data: this.savingsBarChartTarget.dataset.savings.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  redeemsBarChart() {
    new Chart(this.redeemsBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.redeemsBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "Redeems",
          data: this.redeemsBarChartTarget.dataset.redeems.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  productsPieChart() {
    new Chart(this.productsPieChartTarget, {
      type: 'bar',
      data: {
        labels: this.productsPieChartTarget.dataset.productNames.split(","),
        datasets: [{
          label: 'Redeems',
          data: this.productsPieChartTarget.dataset.productRedeems.split(","),
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(50, 50, 150, 1)',
            'rgba(105, 205, 105, 1)',
            'rgba(255, 100, 207, 1)',
            'rgba(225, 225, 225, 1)'
          ],
          hoverOffset: 4
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }
}